<template>
    <div class="container">
        <city-list @selectedCity="selectedCid"></city-list>
       
        <div class="text">
            <div class="textcontent" v-html="htmlData"></div>
        </div>

    </div>
</template>

<script>
import CityList from "@/components/cityreserch/CityList.vue";
import { getLocation, fileDownload } from "../../api/Article.js";
export default {
    components: { CityList },
    data() {
        return {
            htmlData: "",
        };
    },
    computed: {
        currentArea: {
            get() {
                return this.$store.state.currentArea;
            },
            set(v) {
                this.$store.commit("currentArea", v);
            },
        },
    },
    data() {
        return {
            currentCate: null,
            htmlData: ""
        };
    },
    watch: {
        currentArea(nv, ov) {
            this.getData();
        },
    },

    mounted() {
        this.currentCate = this.$router.currentRoute.meta.articleCate;
        this.getData();
    },

    methods: {
        selectedCid() { },

        //获取数据
        getData() {
            const params = {
                city_plate_id: this.currentArea,
                cate: this.currentCate,
            };
            getLocation(params).then((res) => {

                if (res.code === 1001) {
                    this.htmlData = '暂无数据'
                } else {
                    this.htmlData = res.data.content;
                }

            });
        },

        //下载
        download(index, rowData) {
            const params = { id: rowData.id }
            fileDownload(params).then(res => {
                window.location.href = res.data.fileUrl

            })
        }

    },
};
</script>

<style lang="scss" scoped>
.container {
    // width: 99%;
    margin: 0 auto;

    .table {
        width: 96%;
        margin: 0 auto;
    }

 
}

.text {
    color: #333;
    width: 98%;
    background: #f0f3f8;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  }

  .textcontent {

    text-align: left;
    padding: 10px;
    box-sizing: border-box;
   
  }

</style>